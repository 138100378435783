<template>
  <v-list-item
    v-if="item"
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="`/${item.sys_name}`"
    active-class="primary white--text"
    link
    class="py-1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar> -->

    <v-list-item-icon
      v-if="mini"
      class="my-2 align-self-center"
    >
      <v-icon v-text="item.icon ? item.icon : 'mdi-account'" />
    </v-list-item-icon>

    <v-list-item-content class="ml-15 align-self-center" v-if="item.name">
      <v-list-item-title v-text="item.name" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { sync } from 'vuex-pathify'

  export default {
    name: 'DefaultListItem',

    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      ...sync('app', [
        'mini',
      ]),
    },
  }
</script>
